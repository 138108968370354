/* You can add global styles to this file, and also import other style files */
.marginBottom0{
    margin-bottom: 0px;
}
.marginRight0{
    margin-right: 0%;
}
.textRight{
    text-align: right;
}
.width100{
    width: 100%;
}
p{
    font-family: 'sofia Pro', Regular;
}
.fontSize28{font-size: 28px; font-weight: bold;}
.whiteColor{color: #FFFFFF;}
